import * as React from "react"

import Intro from '../components/intro'
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Intro />
    
  </Layout>
)

export default IndexPage
