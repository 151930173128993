import React from 'react';

const Greeting = (props) => {
  const {content, char, color} = props
  const segment = () => {
    return content.split('').map((c, i) => {
      return (
        <span key={i} style={c.toLowerCase() === char.toLowerCase() ? {color: `${color}`} : {}}>
          {c}
        </span>
      )
    })
  }

  return (
    <>
      {segment()}
    </>
  )

}

export default Greeting