import React, {useState, useEffect} from 'react';
import Greeting from './greeting'
import {stringIntersection} from '../helpers/strings'

const Intro = (props) => {
  const [count, setCount] = useState(0)
  const {greetings, introduction, colors} = props
  let greeting = greetings[count % greetings.length]
  let chars = stringIntersection(greeting.toLowerCase(), introduction.toLowerCase())
  let color = colors[count % colors.length]
  let char = chars[count % chars.length]

  // count increases by 1 every 3 seconds
  // used to pick a greeting 
  useEffect(() => {
    const myInterval = setInterval(() => setCount(count + 1), 3000)
    return () => clearInterval(myInterval)
  })

  return (

    <div id="intro-wrapper" className="v-centered centered z-back">
      <div id="greeting-wrapper">
        <h1>
          <Greeting 
            content={greeting} 
            char={char} 
            color={color}
          />
        </h1>
        
        <h2>
          <Greeting 
            content={introduction} 
            char={char} 
            color={color}
          />
        </h2>
      </div>
    </div>

  )
}


Intro.defaultProps = {
  colors: [
    '#FF0000', //red
    '#FFFF00', //yellow
    '#0000FF', //blue,
    '#FF6600', //orange
    '#00FF00', //green 
    '#6600FF' //purple
  ],
  
  greetings: [
    'Hello',
    'Hola',
    'Hallo',
    'Bonjour',
    'Ciao',
    'Shalom',
    'Ni hao'
  ],
  introduction: "My name is Julian"
}

export default Intro